import homepage from './images/homepage.png';
import clan from './images/clan.png';
import wgpowa from './images/wgpowa.jpg';
import logo from './images/logo.png';
import discord from './images/discord.jpg';
import './App.css';

const articles = [
  {
    image: homepage,
    caption: "La page d'accueil",
    title: "Notre ancien site",
    text: "Notre site a été pendant une bonne douzaine d'années au début des années 2000, le haut lieu de rendez-vous de tout joueur de RTS francophone. On trouvait des tutoriels sur nos jeux favoris de l'époque (Age of Empires 3, Warcraft 3, Starcraft 2, Dawn of War), un forum hyper actif et une communauté de membres d'horizons différents mais soudés par la passion de la stratégie en temps réel.<br><br>Malheureusement, l'activité étant réduite depuis plusieurs années, nous avons choisi de l'abandonner et le nom de domaine en \".net\" a été acquis par une entité étrangère. C'est pourquoi nous avons migré sur ce site (et surtout sur <a href=\"https://discord.gg/H96PAG4F\" target=\"_blank\"  class=\"regularLink\">Discord</a>), plus adapté à notre structure actuelle."
  },
  {
    image: clan,
    caption: "La page de présentation du clan",
    title: "Notre clan",
    text: "Le clan WG (War Gamers), composé de membres unis autour des RTS en ligne, a été trés actif à partir de 2003. On dit de nous par exemple que \"Le clan WG avait également une forte présence sur les forums de discussion et les réseaux sociaux dédiés aux jeux de stratégie en ligne.\" Pour nous rejoindre, les volontaires devaient devaient se soumettre à une procédure de vérification et d'approbation par les autres membres qui pouvait durer plusieurs mois.",
  },
  {
    image: wgpowa,
    caption: "IRL Paris, env. 2007",
    title: "Rencontres IRL",
    text: "Périodiquement nous organisions des rencontres IRL, l'équivalent des sorties de \"team building\" à la mode.",
  },
  {
    image: discord,
    caption: "Logo Discord",
    title: "2023 : le renouveau",
    text: "Aujourd'hui nous nous sommes réunis sur Discord et nous jouons à des jeux divers et variés, alors viens nous rejoindre !",
    url: "https://discord.gg/H96PAG4F",
  },
]

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="logoImage" alt="wg power" title="logo WG power"/>
        <h1>Bienvenue sur le nouveau site du clan War Gamers !</h1>
      </header>
      {articles.map((article, index) => {
        
        if (article.url) {
          return (
            <a href={article.url} className="articleLink" key={index} target="_blank" rel="noreferrer">
              <article className="article">
                <div className="articleImageContainer">
                  <figure>
                    <img src={article.image} className="articleImage" alt={article.title}/>
                    <figcaption>{article.caption}</figcaption>
                  </figure>
                </div>
                <div className="articleTextContainer">
                  <p className="articleTitle">{article.title}</p>
                  <p className="articleText" dangerouslySetInnerHTML={{__html: article.text}} />
                </div>
              </article>
            </a>
          )
        }
        return (
          <article className="article" key={index}>
            <div className="articleImageContainer">
              <figure>
                <img src={article.image} className="articleImage" alt={article.title}/>
                <figcaption>{article.caption}</figcaption>
              </figure>
            </div>
            <div className="articleTextContainer">
              <p className="articleTitle">{article.title}</p>
              <p className="articleText" dangerouslySetInnerHTML={{__html: article.text}} />
            </div>
          </article>
        )
      })}
      <h2>A bientôt in game !</h2>
      <footer>Made with <span className="love">&#10084;</span> by <a href="https://www.qodeflix.com" className="authorLink" target="_blank" rel="noreferrer" >qodeflix</a></footer>
    </div>
  );
}

export default App;
